import React from 'react';
import Anagrafiche from '../components/Anagrafiche';

function Home() {
  return (
    <>
        <Anagrafiche />
    </>
  );
}

export default Home;
